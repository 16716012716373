<template>
  <component :is="tag" class="footer-menu">
    <BaseButton
      v-for="route in routes"
      :key="`${route.title}-footer`"
      class="footer-nav-btn"
      :color="route.color || 'white'"
      flat
      small
      :to="route.to ? route.to.path : ''"
      :href="route.href"
      :target="route.target"
      >{{ route.title }}</BaseButton
    >
    <BaseButton
      v-for="(channel, count) of getSocialChannels"
      :key="channel.name"
      :class="[
        'py-0 pl-2 social-channel',
        {
          'pr-2 mr-2': count > 1,
        },
      ]"
      :href="channel.url"
      target="_blank"
      color="transparent"
      :aria-label="channel.name"
    >
      <img
        :src="require(`@assets/images/icon-${channel.name}-white.svg`)"
        :alt="channel.name"
      />
    </BaseButton>

    <BaseButton
      v-if="getContactDetail(location, 'phone_number')"
      color="bloak-pink"
      text-color="white"
      small
      href="https://www.toasttab.com/blue-oak-bbq-900-n-carrollton-st/v3#!/order"
      target="_blank"
      class="mr-0 footer-nav-btn"
      >Order Takeout</BaseButton
    >
  </component>
</template>

<script>
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'
import { mapGetters } from 'vuex'
export default {
  name: 'BlueGiantFooterMenu',
  components: {},
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      routes: [
        {
          name: 'menu',
          title: 'Menu',
          to: { path: '/locations/blue-oak-bbq' }, // TODO (ESS) : Make this loc slug dynamic
          position: 'center',
        },
        {
          title: 'Catering',
          to: { path: '/catering' },
          exact: true,
          position: 'center',
        },
        {
          title: 'Merchandise',
          href: 'https://shop.blueoakbbq.com/collections/merchandise',
          target: '_blank',
          exact: true,
          position: 'center',
        },
        {
          title: 'Press',
          to: { path: '/press' },
          exact: true,
          position: 'center',
        },
        {
          title: 'Contact & Team',
          to: { path: '/contact' },
          exact: true,
          position: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('site', ['getSocialChannels']),
  },
  methods: {
    formatPhone,
    getContactDetail(location, key) {
      return getContactDetail(location, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss">
@import '@styleMixins';

.footer-menu .btn-base {
  @include btn-size(0.8rem, 0.75em);

  margin-right: 0.3em;
  margin-left: 0.3em;

  .v-btn__content {
    @extend .rubik--font;
  }
}
.social-channel {
  height: 32px;
  padding: 0 !important;
  margin: 0 !important;
}
</style>
